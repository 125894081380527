<template>
	<div class="widgetContainer__body">
	<v-app class="vue-app">
		<v-main>
			<v-container fluid>
				<div>
					<v-breadcrumbs :items="breadCrumb" class="pl-0">
						<template v-slot:item="{ item }">
							<v-breadcrumbs-item
								link
								:href="item.href"
								@click.prevent="$router.push(item.href)"
								:disabled="item.disabled"
							>
								{{ item.text }}
							</v-breadcrumbs-item>
						</template>
						<template v-slot:divider>
							<v-icon>mdi-chevron-right</v-icon>
						</template>
					</v-breadcrumbs>
					<div class="pl-0">
						<div class="header-1">
							{{ $t('bill_no') }} {{ refNumber }}
						</div>
					</div>
					<div class="d-flex justify-end mb-4">
						<!-- <v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined>
									<v-icon>mdi-dots-horizontal</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									link
								>
									<v-list-item-title class="text-primary" @click="onToggleMarkReceivedForm">{{ $t('billing_mark_receive') }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu> -->
						<v-btn
							class="text-capitalize blue--text"
							outlined
							@click="onToggleMarkReceivedForm"
							:loading="isDataLoading"
							v-if="data && data.status==='draft'"
						>
							{{ $t('billing_mark_receive') }}
						</v-btn>
						<v-btn
							class="text-capitalize blue--text mx-2"
							outlined
							@click="onToggleEditBill"
							:loading="isDataLoading"
						>
							{{ $t('edit_bill') }}
						</v-btn>
						<!-- <v-btn
							class="primary white--text mx-2 text-capitalize"
							outlined
							large
							@click="showPaymentModal = true"
							:loading="isDataLoading"
						>
							{{ $t('pay_bill') }}
						</v-btn> -->
						<v-btn
							class="text-capitalize red--text"
							outlined
							@click="onToggleDeleteBill"
							:loading="isDataLoading"
						>
							{{ $t('delete') }}
						</v-btn>
					</div>
					<v-card outlined :loading="isDataLoading">
						<v-card-text>
							<!-- <v-row>
								<v-col cols="4">
									<div>
										<span class="form-label text-uppercase">{{ $t('vendor') }}</span>
										<p class="subtitle-1 font-weight-medium">{{ vendor }}</p>
									</div>
									<div>
										<span class="form-label text-uppercase">{{ $t('notes') }}</span>
										<p class="subtitle-1 font-weight-medium">{{ data && data.notes }}</p>
									</div>
								</v-col>
								<v-col cols="4">
									<span class="form-label text-uppercase">{{ $t('mailing_address') }}</span>
									<p class="subtitle-1 text-uppercase font-weight-medium">{{ email }}</p>
								</v-col>
								<v-col cols="4">
									<div>
										<span class="form-label text-uppercase">{{ $t('bill_date') }}</span>
										<span class="ml-4 subtitle-1 text-uppercase font-weight-medium">{{ billDate }}</span>
									</div>
									<div>
										<span class="form-label text-uppercase">{{ $t('due_date') }}</span>
										<span class="ml-4 subtitle-1 text-uppercase font-weight-medium">{{ dueDate }}</span>
									</div>
								</v-col>
							</v-row> -->
							<v-row>
								<v-col cols="12" sm="4">
									<div>
										<span class="form-label text-uppercase">{{ $t('vendor') }}</span>
										<p class="subtitle-1 font-weight-medium">{{ vendor }}</p>
									</div>
									<div>
										<span class="form-label text-uppercase">{{ $t('mailing_address') }}</span>
										<p class="subtitle-1 text-uppercase font-weight-medium">{{ email }}</p>
									</div>
									<div>
										<span class="form-label text-uppercase">{{ $t('notes') }}</span>
										<p class="subtitle-1 font-weight-medium">{{ data && data.notes }}</p>
									</div>
								</v-col>
								<v-col cols="12" sm="4">
									<div>
										<span class="form-label text-uppercase">{{ $t('bill_date') }}</span>
										<p class="subtitle-1 text-uppercase font-weight-medium">{{ billDate }}</p>
									</div>
									<div class="">
										<span class="form-label text-uppercase">{{ $t('due_date') }}</span>
										<p class="subtitle-1 text-uppercase font-weight-medium">{{ dueDate }}</p>
									</div>
									<div class="">
										<span class="form-label text-uppercase">{{ $t('status') }}</span>
										<p class="subtitle-1 text-uppercase font-weight-medium">
											<v-chip dark :color="statusColor" v-text="data && data.status" small class="text-uppercase" v-if="!isDataLoading"/>
										</p>
									</div>
								</v-col>
								<v-col cols="12" sm="4">
									<div>
										<span class="form-label text-uppercase">{{ $t('currencyname') }}</span>
										<p class="subtitle-1 text-uppercase font-weight-medium">{{ currency }}</p>
									</div>
								</v-col>
							</v-row>
						</v-card-text>
						<v-divider></v-divider>
						<v-card-text>
							<h5 class="subtitle-1 font-weight-bold text-dark labelcolor--text">{{ $t('outstanding_transactions') }}</h5>
						</v-card-text>
						<v-data-table hide-default-footer :items="items" :headers="outstandingTransactionsHeaders" class="v-table-middle-align">
							<template v-slot:[`item.price`]="{ item }">
								<h5 v-text="formatCurrency(item.price)"/>
							</template>
							<template v-slot:[`item.total`]="{ item }">
								<h5 v-text="formatCurrency(item.total)"/>
							</template>
						</v-data-table>
						<v-card-text>
							<v-row class="mt-4">
								<v-col cols="6" sm="10"></v-col>
								<v-col>
									<v-row class="">
										<v-col class="text-right font-weight-bold">{{ $t('subtotal') }}</v-col>
										<v-col class="text-right font-weight-bold">{{ currencyFormat(subTotal) }}</v-col>
									</v-row>
									<v-row class="">
										<v-col class="text-right font-weight-medium">{{ $t('tax') }}</v-col>
										<v-col class="text-right font-weight-bold">{{ new Intl.NumberFormat('en-US').format(taxTotal) }}</v-col>
									</v-row>
									<v-row>
										<v-col class="text-right font-weight-bold">{{ $t('total') }}</v-col>
										<v-col class="text-right font-weight-bold">{{ currencyFormat(subTotal + taxTotal) }}</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card-text>
						<v-divider></v-divider>
					</v-card>
					<billing-form :open="showEditModal" :form-values="formData" :is-edit-mode="true" @toggle="onToggleEditBill"></billing-form>
					<bill-make-payment-form :open="showPaymentModal" :form-values="data" @toggle="showPaymentModal = !showPaymentModal"></bill-make-payment-form>
					<bill-delete :open="showDeleteModal" :form-data="data" @toggle="onToggleDeleteBill"></bill-delete>
					<bill-received :open="showMarkReceivedModal" :form-data="data" @toggle="onToggleMarkReceivedForm"></bill-received>
				</div>
			</v-container>
		</v-main>
	</v-app>
	</div>
</template>

<script>

import BillingForm from "../components/BillingForm.vue";
import BillMakePaymentForm from '../components/BillMakePaymentForm.vue';
import BillDelete from '../components/BillingDeleteModal.vue';
import BillReceived from '../components/BillMarkReceivedModal.vue';

import { apiErrorMessage } from '@/utils/general';
import moment from 'moment';
import accountingMixin from '../mixins/accounting';

import { mapActions } from 'vuex';

export default {
	components: {
		BillingForm,
		BillMakePaymentForm,
		BillDelete,
		BillReceived,
	},
	mixins: [accountingMixin],

	data() {
		return {
			showEditModal: false,
			showPaymentModal: false,
			showDeleteModal: false,
			data: null,
			isDataLoading: true,
			formData: null,
			showMarkReceivedModal: false,
		}
	},

  created() {
		this.fetchBill();
  },
  computed: {
	  outstandingTransactionsHeaders(){
		  return [
			  { text: this.$t('category') , value: 'item.name', align: 'left', class: 'th--text font-weight-bold' },
			  { text: this.$t('description') , value: 'description', align: 'left', class: 'th--text font-weight-bold' },
			  { text: this.$t('quantity') , value: 'quantity', align: 'right', class: 'th--text font-weight-bold' },
			  { text: this.$t('amount') , value: 'price', align: 'right', class: 'th--text font-weight-bold' },
			  { text: this.$t('total') , value: 'total', align: 'right', class: 'th--text font-weight-bold' },
		  ]
	  },

		breadCrumb() {
			return [
        {
          text: this.$t('side_menu_accounting'),
          disabled: true,
          href: '#',
        },
				{
          text: this.$t('side_menu_bills'),
          disabled: false,
          href: '/accounting/bills',
        },
        {
          text: this.refNumber || '',
          disabled: true,
          href: '#',
        },
      ];
		},

		refNumber: {
			get() {
				return this.data ? this.data.document_number : '';
			}
		},

		vendor: {
			get() {
				return this.data ? this.data.contact_name : '';
			}
		},

		email: {
			get() {
				return this.data ? this.data.contact.data.email : ''
			}
		},

		billDate: {
			get() {
				return this.data ? this.moment(this.data.issued_at).format('MMM DD, YYYY') : '';
			}
		},

		dueDate: {
			get() {
				return this.data ? this.moment(this.data.due_at).format('MMM DD, YYYY') : '';
			}
		},

		items: {
			get() {
				return this.data && this.data.items.data.length ? JSON.parse(this.data.items_ref) : [];
			}
		},

		subTotal:{
			get() {
				if(this.items.length) {
					return this.items.reduce((c, n) => c + (Number(n.price || 0) * Number(n.quantity || 0)) ,0);
				}
				return 0;
			}
		},

		taxTotal() {
		/* 	if(this.data.billCategories.length) {
				return this.data.billCategories.reduce((c, n) => c + Number(n.taxAmount) ,0);
			} */
			return 0;
		},

		currency() {
      return this.data?.currency?.data?.name;
    },

		statusColor: {
			get() {
				if(this.data) {
					switch(this.data.status.toLowerCase()){
						case 'received': return 'red darken-4';
						case 'paid': return 'green darken-4';
						case 'draft': return 'purple darken-4';
						case 'partial': return 'blue darken-4';
						case 'cancelled': return 'black';
					}
				}
				return '';
			}
		},

		vendorCurrency() {
      const vendor = JSON.parse(this.data.contact_ref) || {};
      return vendor?.currency_code || 'USD';
    },

  },
  methods: {
    //
		...mapActions('accounting', ['getBill']),
		moment,

		formatCurrency(value) {
			return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
		},

		fetchBill() {
			this.getBill(this.$route.params.id)
			.then(response => {
				this.data = response.data.data.data;
			})
			.catch(
				error => apiErrorMessage(error)
			)
			.finally(() => {
				this.isDataLoading = false;
			});
		},

		onToggleEditBill(options = {}) {
			this.showEditModal = !this.showEditModal;
			this.formData = null;

			if(this.showEditModal) {
				this.formData = JSON.parse(JSON.stringify(this.data));
			}

			if(options.updated) {
			  this.$notify({
          title: 'Message',
          message: options.message || 'Updated.',
          type: 'success'
         });
			  this.fetchBill();
      }
		},

		onToggleDeleteBill(data = null, options = {}) {
			this.showDeleteModal = !this.showDeleteModal;
			 if(options.deleted) {
				 this.$notify({
          title: 'Message',
          message: options.message || 'Deleted.',
          type: 'success'
         });
				 this.$router.push('/accounting/bills');
      }
		},

		onToggleMarkReceivedForm(data = null, options = {}) {
      this.showMarkReceivedModal = !this.showMarkReceivedModal;
      if(options.received) {
        this.$notify({
					icon: 'mdi-check',
					type: 'success',
					message: options.message || 'Mark as received.',
				});
        this.fetchBill();
      }
    },

  }

};
</script>
<style lang="scss" scoped>
	$button-bg-color: #0171a1;
	$form-label: #819fb2;
	.vue-app {
		background: none;
	}

	.form-label {
		color: $form-label;
		font-weight: bold;
	}

	hr {
		border-color: #ffffff !important;
	}
	.btn-primary {
		background-color: $button-bg-color !important;
		color: #fff !important;
	}
</style>
<style lang="scss">
	.v-table-middle-align {
		width: 100%;
    td, th {
      vertical-align: middle !important;
    }
  }
</style>